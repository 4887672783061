import React, {RefObject, useEffect, useState} from 'react';
import {AutoComplete, Card, Col, Descriptions, FormInstance, Input, message, Row, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import {DictObjectType, registerType} from "../common/DhDictData";
import DhZoneSelect from "../patientApplication/DhZoneSelect";
import {ID_NUMBER} from "../../common/VerifyRegex";
import {DhDetailDataType} from "../common/DhDetailType";
import {genderArr} from "../../cooperate/util/AierCommon";


type EditBasicInfoType = DictObjectType & {
    form: RefObject<FormInstance>;
    setAddressSelect: any,
    resultData: DhDetailDataType['data']
};

type TaskDataType = {
    createdDate: string;
    registerType: number;
};
export default function EditBasicInfoByFee(props: EditBasicInfoType) {


    const [areaStr, setAreaStr] = useState<string>('');
    const [addressSelect, setAddressSelect] = useState<[] | undefined>([]);
    const [searchTimeout, setSearchTimeout] = useState<any>([]);


    useEffect(() => {

        let serviceIdentity = props.form.current?.getFieldValue("serviceIdentity");
        if (!serviceIdentity) {
            props.form.current?.setFieldsValue({
                serviceIdentity: '1',
                tempArea: props.resultData.addressDetail?.areaStr
            });
        }
        if (props.resultData.addressDetail?.areaStr) {
            setAreaStr(props.resultData.addressDetail?.areaStr)
        }
    }, [])

    useEffect(() => {

        props.setAddressSelect(addressSelect)
    }, [addressSelect]);

    const searchAddr = (addr: string) => {

        if (!areaStr || !areaStr.trim()) {
            message.error('请选择省市区');
            return
        }
        if (addr && addr.trim().length > 0) {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
            setAddressSelect([])
            setSearchTimeout(setTimeout(() => {
                doSearch(addr)
            }, 600))
        }
    }

    const locationAddrSelect = (e: string) => {
        const addr = e.split(' ')[1];
        props.form.current?.setFieldsValue({
            addressDetail: addr,
        });
    }

    const doSearch = (addr: string) => {

        Fetch.get(
            `${API.location2}?keyword=${addr.trim()}&region=${
                areaStr
            }`
        ).then((data) => {
            const result = JSON.parse(data);
            if (result.status === 0 && result.data && result.data.length > 0) {
                const options = result.data.map((item: any) => {
                    return {
                        label: item.title + ' ' + item.address,
                        value: item.title + ' ' + item.address,
                        location: item.location,
                    };
                });
                // setLocationChange(true)
                setAddressSelect(options)
            } else {
                message.error("没有查询到地址信息，请检查省市区信息。")
            }
        });
    }

    const setAreaStrFun = (val: string) => {

        setAreaStr(val);
        if (!val) {
            setAddressSelect(undefined)
            props.form.current?.setFieldsValue({
                locationAddr: '',
            });
        } else {
            props.form.current?.setFieldsValue({
                tempArea: val
            });
        }
    };

    return (
        <>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Card size="small"
                      title={`基本信息`}
                      extra={<></>}
                >
                    <Row gutter={20}>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="姓名">
                                    {props.resultData.userDetail?.name}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="性别">
                                    {props.resultData.userDetail?.gender && (
                                        <>
                                            {genderArr[props.resultData.userDetail?.gender]}
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="年龄">
                                    {props.resultData.userDetail?.age}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="出生日期">
                                    {props.resultData.userDetail?.dateBirth}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        {props.resultData.userDetail?.idNumber ? (
                            <Col span={10}>
                                <Descriptions>
                                    <Descriptions.Item label="身份证号">
                                        {props.resultData.userDetail?.idNumber}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        ) : (
                            <Col span={10}>
                                <FormItem
                                    name="idNumber"
                                    label="身份证号"
                                    rules={[{required: true, message: '必填'}, ID_NUMBER]}
                                >
                                    <Input style={{width: '70%'}}
                                        // onFocus={handleIdNumberFocus}
                                        // onBlur={handleIdNumberBlur}
                                        // onChange={calculateAgeAndGender}
                                           placeholder="身份证号"/>
                                </FormItem>
                            </Col>
                        )}
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="关联医院">
                                    {props.resultData.userDetail?.hospitalName}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="预计缺牙颗数">
                                    {props.resultData.userDetail?.toothNum}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={10}>
                            <Descriptions>
                                <Descriptions.Item label="报名来源">
                                    {props.resultData.userDetail?.registerType && (
                                        <>
                                            {registerType[props.resultData.userDetail?.registerType]}
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        {props.resultData.addressDetail?.areaStr &&
                        props.resultData.addressDetail?.locationAddr ? (
                            <Col span={10}>
                                <Descriptions>
                                    <Descriptions.Item label="省市区">
                                        {props.resultData.addressDetail?.areaStr}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        ) : (
                            <FormItem
                                name="tempArea"
                                label="居住省份"
                                rules={[{required: true, message: '必填'}]}
                            >
                                <DhZoneSelect
                                    setAreaStr={setAreaStrFun}
                                    form={props.form}
                                    loadData={{
                                        provinceId: props.resultData.addressDetail?.provinceId || 0,
                                        cityId: props.resultData.addressDetail?.cityId || 0
                                    }}
                                />
                            </FormItem>
                        )}
                    </Row>
                    <Row gutter={20}>
                        {props.resultData.addressDetail?.locationAddr ? (
                            <Col span={10}>
                                <Descriptions>
                                    <Descriptions.Item label="居住地址">
                                        {props.resultData.addressDetail?.locationAddr}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        ) : (
                            <Col span={10}>
                                <FormItem
                                    name="locationAddr"
                                    label="定位地址"
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <AutoComplete
                                        style={{width: '70%'}}
                                        options={addressSelect}
                                        onSearch={searchAddr}
                                        onSelect={locationAddrSelect}
                                    />
                                </FormItem>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={20}>
                        {props.resultData.addressDetail?.address ? (
                            <Col span={10}>
                                <Descriptions>
                                    <Descriptions.Item label="详细地址">
                                        {props.resultData.addressDetail?.address}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        ) : (
                            <Col span={10}>
                                <FormItem
                                    name="addressDetail"
                                    label="详细地址"
                                    rules={[{required: true, message: '必填'}]}
                                >
                                    <Input style={{width: '70%'}}
                                           placeholder="详细地址"/>
                                </FormItem>
                            </Col>
                        )}
                    </Row>
                </Card>
            </Space>
        </>
    );
}
